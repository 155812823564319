import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const Billing = Loadable(lazy(() => import('./Billing')))
// const BillingGrid = Loadable(lazy(() => import('./BillingGrid')))
const BillHistory = Loadable(lazy(() => import('./BillHistory')))
const ShopInvoice = Loadable(lazy(() => import('./ShopInvoice')))

const billingRoutes = [
    {
        path: '/billing',
        element: <Billing />,
    },
    {
        path: '/billing/history',
        element: <BillHistory />,
    },
    {
        path: '/shop_invoice',
        element: <ShopInvoice />,
    },
]

export default billingRoutes
