import _ from 'lodash'
import i18n from 'i18next'
import { numberFieldCorrector, preciseRounding } from 'app/utils/calculation'

const currencyInfo = JSON.parse(localStorage.getItem('currencyInfo'))

export const integerOnlyValidator = (value) => {
    if (value.length) {
        let inputPattern = /^[0-9]+$/
        if (!inputPattern.test(value)) {
            return i18n.t('errorMsg.number_only')
        } else return ''
    }
    return ''
}

export const integerAllowNegativeValidator = (value) => {
    if (value.length) {
        // let inputPattern = /^(-)[0-9]+$|[0-9]+$/
        let inputPattern = /^[\-?0-9]+$/
        if (!inputPattern.test(value)) {
            return i18n.t('errorMsg.invalid_number')
        } else return ''
    }
    return ''
}

export const nameValidator = (name) => {
    if (name) {
        if (name.match(/^[/A-Za-z\p{Unified_Ideograph}' ]+$/u)) {
            return ''
        } else if (name.length > 150) {
            return i18n.t('errorMsg.exceed_150_char')
        } else return i18n.t('errorMsg.invalid_name')
    }
    return ''
}

export const generalNameValidator = (name) => {
    if (name.length) {
        if (name.length > 100) {
            return i18n.t('errorMsg.exceed_100_char')
        } else return ''
    }
    return ''
}

export const fieldWithSpecialCharValidator = (name) => {
    if (name) {
        if (name.match(/^[/A-Za-z0-9\p{Unified_Ideograph}'& \[\]()•.-]+$/u)) {
            return ''
        } else if (name.length > 150) {
            return i18n.t('errorMsg.exceed_150_char')
        } else return i18n.t('errorMsg.invalid_name')
    }
    return ''
}

export const companyCodeValidator = (value) => {
    if (value) {
        if (value.length > 8) {
            return i18n.t('errorMsg.exceed_7_char')
        } else if (
            value.match(/^[/A-Za-z\p{Unified_Ideograph}'& \[\]()-]+$/u)
        ) {
            return ''
        } else {
            return i18n.t('errorMsg.invalid_name')
        }
    }
    return ''
}

export const contactValidator = (contact) => {
    if (contact) {
        if (!new RegExp(['^[0-9]+$']).test(contact) || contact.length > 15) {
            return i18n.t('errorMsg.invalid_contact')
        } else return ''
    }
    return ''
}

export const emailValidator = (email) => {
    let emailPattern2 = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailPattern2.test(email)) {
        return i18n.t('errorMsg.invalid_email')
    } else if (email.length > 250) {
        return i18n.t('errorMsg.exceed_250_char')
    } else return ''
}

export const confirmEmailValidator = (email) => {
    if (email) {
        let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!emailPattern.test(email)) {
            return i18n.t('errorMsg.invalid_email')
        } else return ''
    }
    return ''
}

export const addressValidator = (address) => {
    if (address) {
        if (!new RegExp('^[/A-Za-z0-9 &,#-.]+$').test(address)) {
            return i18n.t('errorMsg.invalid_address')
        } else if (address.length > 200) {
            return i18n.t('errorMsg.exceed_200_char')
        } else return ''
    }
    return ''
}

export const charExceedValidation = (value) => {
    if (value && value.length) {
        if (value.length > 200) {
            return i18n.t('errorMsg.exceed_200_char')
        } else return ''
    }
    return ''
}

export const inputValidation = (value) => {
    if (value.length > 100) {
        return i18n.t('errorMsg.exceed_100_char')
    } else return ''
}

export const charValidator = (value) => {
    if (value.length > 200) {
        return i18n.t('errorMsg.exceed_200_char')
    } else return ''
}

export const emptyValidator = (value) => {
    if (value) {
        if (value == null || value == '') {
            return i18n.t('errorMsg.required_field')
        } else return ''
    } else return i18n.t('errorMsg.required_field')

}

export const packageTableValidator = (service, product, credit) => {
    if (service == null && product == null && credit == null) {
        return ''
    } else return i18n.t('errorMsg.required_field')
}

export const emptyLengthValidator = (value) => {
    if (value) {
        if (value == null || value == '') {
            return i18n.t('errorMsg.required_field')
        } else return ''
    } else return i18n.t('errorMsg.required_field')
}

export const confirmEmptyValidator = (value) => {
    if (value.length == null || value.length == '') {
        return i18n.t('errorMsg.required_field')
    } else return ''
}

export const decimalValidator = (value) => {
    if ([null, undefined].includes(value)) return value

    let valuePattern = /^[0-9]{1,10}?$|(?=^.{1,11}$)^[0-9]+\.\d{0,2}$/
    if (value.length) {
        if (!new RegExp('^[0-9.]+$').test(value)) {
            return i18n.t('errorMsg.invalid_number')
        } else if (
            !valuePattern.test(value) ||
            value <= -0.001 ||
            value.length > 11
        ) {
            return i18n.t('errorMsg.invalid_number')
        } else return ''
    }
    return ''
}

export const percentageValidator = (value, type) => {
    let valuePattern = /^[0-9]{1,10}?$|(?=^.{1,11}$)^[0-9]+\.\d{0,2}$/
    let percentagePattern = /^[0-9]{1,4}?$|(?=^.{1,6}$)^[0-9]+\.\d{0,2}$/
    if (value.length) {
        if (type == 2) {
            if (!valuePattern.test(value) || value.length > 11) {
                return i18n.t('errorMsg.invalid_number')
            } else return ''
        } else {
            if (
                !percentagePattern.test(value) ||
                value >= 100.001 ||
                value <= -0.001 ||
                value.length > 6
            ) {
                return i18n.t('errorMsg.invalid_percentage')
            } else return ''
        }
    }
    return ''
}

export const zeroValidatior = (value) => {
    if (value.length) {
        if (value <= 0.001) {
            return i18n.t('errorMsg.invalid_number')
        } else return ''
    }
    return ''
}

export const urlValidator = (value) => {
    if (value.length) {
        let valuePattern =
            /[a-z0-9-\.]+\.[a-z]{,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?$/
        if (value.match(valuePattern)) {
            return i18n.t('errorMsg.invalid_url')
        } else return ''
    }
    return ''
}

export const confimURLValidator = (value) => {
    if (value?.length) {
        // https://www.regextester.com/104035
        let valuePattern =
            /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/
        if (!valuePattern.test(value)) {
            return i18n.t('errorMsg.invalid_url')
        } else return ''
    }
    return ''
}

export const regNtaxNoValidator = (value) => {
    if (value.length) {
        if (!new RegExp('^[a-zA-Z0-9 -]+$').test(value)) {
            return i18n.t('errorMsg.invalid_value')
        } else if (value.length > 31) {
            return i18n.t('errorMsg.exceed_30_char')
        } else return ''
    }
    return ''
}

export const usernameValidator = (value) => {
    if (value.length) {
        if (!new RegExp(['^[a-zA-Z0-9]+$']).test(value)) {
            return i18n.t('errorMsg.alphanumeric_only')
        } else if (value.length >= 100) {
            return i18n.t('errorMsg.exceed_100_char')
        } else return ''
    }
    return ''
}

export const access_codeValidator = (value) => {
    if (value.length) {
        if (!new RegExp(['^[0-9]+$']).test(value)) {
            return i18n.t('errorMsg.number_only')
        } else if (value.length > 4) {
            return i18n.t('errorMsg.exceed_4_char')
        } else return ''
    }
    return ''
}

export const confirmAccess_codeValidator = (value) => {
    if (value) {
        if (!new RegExp(['^[0-9]+$']).test(value)) {
            return i18n.t('errorMsg.number_only')
        } else if (value.length < 4) {
            return i18n.t('errorMsg.exceed_4_char')
        }
        return ''
    }
    return ''
}

export const signInPasswordValidator = (value) => {
    if (value) {
        if (value.length <= 0) {
            return i18n.t('errorMsg.required_field')
        }
        return ''
    }
    return ''
}

export const passwordValidator = (value) => {
    if (value) {
        if (!new RegExp(['^[a-zA-Z0-9]+$']).test(value)) {
            return i18n.t('errorMsg.alphanumeric_only')
        } else if (value.length > 20) {
            return i18n.t('errorMsg.invalid_password_length')
        }
        return ''
    }
    return ''
}

export const confirmPasswordValidator = (value) => {
    if (value) {
        let passwordPattern = /^[a-zA-Z0-9]+$/
        if (value.length < 6) {
            return i18n.t('errorMsg.invalid_password_length')
        } else if (!value.match(passwordPattern) || value < 6) {
            return i18n.t('errorMsg.alphanumeric_only')
        }
        return ''
    }
    return ''
}

export const confirmPasswordSameValidator = (password, confirmPassword) => {
    if (confirmPassword != password) {
        return i18n.t('errorMsg.invalid_confirmPassword')
    }
    return ''
}

export const privacyPolicyValidator = (value) => {
    if (value == false) {
        return ' '
    }
    return ''
}

export const endTimeCompareValidator = (start, end) => {
    if (start >= end || start == end) {
        return i18n.t('errorMsg.invalid_endTime')
    }
    return ''
}

export const startTimeCompareValidator = (start, end) => {
    if (start >= end || start == end) {
        return i18n.t('errorMsg.invalid_startTime')
    }
    return ''
}

export const discountValidator = (discountObj, maxDiscount) => {
    if (discountObj.type == 1) {
        if (Number(discountObj.amount) > 100) {
            return { amount: 100, type: discountObj.type }
        } else {
            return {
                amount: discountObj.amount || '',
                type: discountObj.type,
            }
        }
    } else {
        if (Number(discountObj.amount) > Number(maxDiscount)) {
            return { amount: maxDiscount, type: 2 }
        } else {
            return { amount: discountObj.amount || '', type: 2 }
        }
    }
}

export const creditExceedValidator = (credit_applied, total_credit) => {
    if (credit_applied >= total_credit) {
        return i18n.t('errorMsg.invalid_credit')
    }
    return ''
}

export const largerSmallerValidator = (value1, value2) => {
    if (value1 >= value2) {
        return i18n.t('errorMsg.invalid_number')
    }
    return ''
}

export const commissionValidator = (value1, value2) => {
    if (value1 > value2) {
        return i18n.t('errorMsg.invalid_number')
    }
    return ''
}

export const msiaPhoneValidator = (value) => {
    let cleanedNumber = value.replace(/^0+|[^0-9]+/g, '')
    // Validate the phone number using a regular expression
    return /^[1-9][0-9]{7,9}$/.test(cleanedNumber)
}

export const categorySaleCommissionValidator = (categoryItem, commission_key, key, value, type, index) => {
    let temp_commission = _.cloneDeep(categoryItem[commission_key])
    let commission_amount = 0
    let sale_amount = 0
    let new_amount = 0


    if (['commission_amount', 'sale_amount'].includes(key)) {
        temp_commission[index][key] = numberFieldCorrector(value)
    } else {
        //This is for the type
        temp_commission[index][key] = value
    }

    // GET THE NEWLY ENTERED VALUE
    if (temp_commission[index][`${type}_type`] == 1) {
        // DONT ALLOW THE VALUE GREATER THAN 100
        let temp = Number(temp_commission[index][`${type}_amount`]) > 100 ? 100 : Number(temp_commission[index][`${type}_amount`])
        new_amount = preciseRounding(Number(categoryItem.price) * temp / 100, false, currencyInfo.rounding_decimal)
    } else {
        new_amount = Number(temp_commission[index][`${type}_amount`])

    }
    // CONVERT IT NUMBER TYPE
    new_amount = Number(new_amount)

    if (temp_commission[index][`${type}_type`] == 1) {
        // ENSURE THE VALUE IS NOT GREATER THAN 100%
        if (Number(temp_commission[index][`${type}_amount`]) > 100) {
            temp_commission[index][`${type}_amount`] = 100
        }
    } else if (Number(categoryItem.price) && !temp_commission[index][`${type}_type`]) {
        // IF THERE IS NO TYPE SELECTED THE SET IT TO 2 BY DEFAULT
        temp_commission[index][`${type}_type`] = 2
    }


    let validator = true
    temp_commission.map(el => {
        if (el.commission_type == 1) {
            commission_amount += Number(categoryItem.price) * Number(el.commission_amount) / 100
        } else if (el.commission_type == 2) {
            commission_amount += Number(el.commission_amount)
        }

        if (el.sale_type == 1) {
            sale_amount += Number(categoryItem.price) * Number(el.sale_amount) / 100
        }

        else if (el.sale_type == 2) {
            sale_amount += Number(el.sale_amount)
        }
    })


    if (commission_amount > Number(categoryItem.price) || sale_amount > Number(categoryItem.price) || !Number(categoryItem.price)) {
        validator = false
    }


    if (!validator) {
        let amount = 0
        if (type == 'commission') {
            amount = commission_amount
        } else {
            amount = sale_amount
        }

        let remaining_balance = Number(categoryItem.price) - (amount - new_amount)

        if (!Number(categoryItem.price)) {
            remaining_balance = 0
        }

        if (temp_commission[index][`${type}_type`] == 1) {
            if (Number(categoryItem.price)) {
                temp_commission[index][`${type}_amount`] = preciseRounding((remaining_balance / Number(categoryItem.price)) * 100, false, currencyInfo.rounding_decimal)
            } else {
                temp_commission[index][`${type}_amount`] = remaining_balance
            }
        } else {
            temp_commission[index][`${type}_amount`] = Number(remaining_balance).toFixed(currencyInfo.rounding_decimal)
        }
    }


    return { temp_commission, commission_amount, sale_amount }
}

export const checkOutstandingInvSelectedItem = (itemList) => {
    let selected = _.find(itemList, obj => _.isEqual(_.get(obj, 'is_selected'), true)) !== undefined;
    return selected
}