import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet"
import { Provider } from 'react-redux'
import { useRoutes } from 'react-router-dom'
import './app.css'
import { Store } from './redux/Store'
import { AllPages } from './routes/routes'
import Theme from './components/Theme/theme'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import NotificationBar from 'app/components/NotificationBar'
import NetworkIndicator from './components/NetworkIndicator'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { register } from './serviceWorkerRegistration';


const App = () => {
    const all_pages = useRoutes(AllPages())
    const [updateAvailable, setUpdateAvailable] = useState(false)
    const [registration, setRegistration] = useState(null);

    useEffect(() => {
        register({
            onUpdate: (registration) => {
                console.log('A new version is available. Ready to update!');
                // Handle app update logic here (e.g., show a Snackbar)
                setUpdateAvailable(true);
                setRegistration(registration);
            },
        });
    }, []);


    const handleCloseUpdateSnackbar = () => {
        // setUpdateAvailable(false)
        if (registration && registration.waiting) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });

            // Hide the Access Code Dialog if it is showed
            localStorage.setItem('userLockStatus', false)
            // Clear off the logged in user info
            localStorage.removeItem('activeUser')
            localStorage.removeItem('loginInfo')
            // Redirect the user to the login page
            window.location.replace('/dashboard_login')
        }
    }

    return (
        <Provider store={Store}>
            <SettingsProvider>
                <Theme>
                    <NetworkIndicator />
                    <AuthProvider>
                        <Helmet>
                            <title>LaBéau Dashboard</title>
                        </Helmet>
                        {all_pages}
                        <NotificationBar handleUpdate={handleCloseUpdateSnackbar} open={updateAvailable} />
                        {/* <HomeScreenShortcut /> */}
                    </AuthProvider>
                </Theme>
            </SettingsProvider>
        </Provider>
    )
}

export default App
