// serviceWorkerRegistration.js
export function register(config) {
    if ('serviceWorker' in navigator) {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

        navigator.serviceWorker
            .register(swUrl)
            .then((registration) => {
                if (registration.waiting) {
                    if (config && config.onUpdate) {
                        config.onUpdate(registration);
                    }
                }

                registration.onupdatefound = () => {
                    const installingWorker = registration.installing;
                    if (installingWorker) {
                        installingWorker.onstatechange = () => {
                            if (
                                installingWorker.state === 'installed' &&
                                navigator.serviceWorker.controller
                            ) {
                                // New content is available
                                if (config && config.onUpdate) {
                                    config.onUpdate(registration);
                                }
                            }
                        };
                    }
                };
            })
            .catch((error) => {
                console.error('Error during service worker registration:', error);
            });
    }
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister();
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
}
